import "../style/services.css";
import iOS from "../images/iOS.png";
import Android from "../images/Android.png";
import Flutter from "../images/Flutter.png";
import HTML from "../images/HTML_dev.png";
import CSS from "../images/CSS_dev.png";
import React from "../images/React_dev.png";
import JS from "../images/JS.png";
import Python from "../images/Python_dev.png";
import Flask from "../images/Flask.png";
import DJ from "../images/Django.png";
import Node from "../images/Node.png";
import Photoshop from "../images/photoshop.png";
import Figma from "../images/figma.png";
import AI from "../images/ui_Ai.png";
import React_native from "../images/React_native.png";
import Web from "../images/web-devlopment.gif"
import Mobile from "../images/mobile-devlopment.gif"
import Backend from "../images/Backend.gif"
import Uiux from "../images/uiux.gif"

const Service = () => {
  return (
    <>
      <section id="services" >
        <div className="services">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-md-7" data-aos="fade-right">
                <div className="services-heading">
                  Web<span className="heading-span"> Development</span>
                </div>
                <p>
                  As mobile devices continue to dominate the digital landscape,
                  having a mobile app for your business is no longer a luxury,
                  but a necessity. A well-designed mobile app can help you
                  connect with your customers, enhance their user experience,
                  and drive business growth.{" "}
                </p>
                <p >
                  At Ashirwad Infotech, we offer top-notch mobile app
                  development services that are tailored to meet your unique
                  business requirements.
                </p>
                <div className="row justify-content-center">
                  <div className="col-2">
                    <img alt="service-img" src={HTML} className="img-hover" />
                  </div>
                  <div className="col-2">
                    <img alt="service-img" src={CSS} className="img-hover" />
                  </div>
                  <div className="col-2">
                    <img alt="service-img" src={JS} className="img-hover" />
                  </div>
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={React}
                      className="img-hover"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-5" data-aos="fade-left">
                <img

                  src={Web}
                  className="img mx-auto d-block"
                  alt="service-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7 order-md-2" data-aos="fade-left">
                <div className="services-heading">
                  Mobile App <span className="heading-span"> Development</span>
                </div>
                <p>
                  In today's digital world, a strong online presence is
                  essential for businesses to succeed. Your website serves as
                  the virtual face of your business, representing your brand,
                  products, and services to the online audience.{" "}
                </p>
                <p>
                  At Ashirwad Infotech, we offer professional web development
                  services that help you create a powerful online presence and
                  achieve your business goals.
                </p>
                <div className="row justify-content-center">
                  <div className="col-2">
                    <img alt="service-img" src={iOS} className="img-hover" />
                  </div>
                  <div className="col-2">
                    <img
                      src={Android}
                      className="img-hover"
                      alt="service-img"
                    />
                  </div>
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={Flutter}
                      className="img-hover"
                    />
                  </div>
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={React_native}
                      className="img-hover"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-5 order-md-1" data-aos="fade-right">
                <img

                  src={Mobile}
                  className="img mx-auto d-block"
                  alt="service-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7" data-aos="fade-right">
                <div className="services-heading">
                  Backend <span className="heading-span"> Development</span>
                </div>
                <p>
                  Ashirwad Infotech is committed to providing reliable backend
                  development services that empower your website with robust
                  functionality, performance, security, and scalability. With
                  our expertise, customized solutions, focus on security and
                  data privacy, integration capabilities, testing and quality
                  assurance, and ongoing support, we can help you create a
                  backend that powers your website's success.{" "}
                </p>
                <p>
                  Contact us today to discuss your backend development needs and
                  ensure your website operates at its best.
                </p>
                <div className="row justify-content-center">
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={Python}
                      className="img-hover"
                    />
                  </div>

                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={Flask}
                      className="img-hover"
                    />
                  </div>

                  <div className="col-2">
                    <img alt="service-img" src={DJ} className="img-hover" />
                  </div>
                  <div className="col-2">
                    <img alt="service-img" src={Node} className="img-hover" />
                  </div>
                </div>
              </div>
              <div className="col-md-5" data-aos="fade-left">
                <img
                  src={Backend}
                  className="img mx-auto d-block"
                  alt="service-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 order-md-5" data-aos="fade-left">
                <div className="services-heading">
                  UI/UX Designing{" "}
                  <span className="heading-span"> Development</span>
                </div>
                <p>
                  At Ashirwad Infotech, we are dedicated to providing engaging
                  UI/UX development services that create seamless and enjoyable
                  user experiences on your website.
                </p>
                <p>
                  With our user-centric approach, customized design solutions,
                  responsive design, visual appeal, and branding, usability and
                  accessibility focus, interaction design expertise, and testing
                  and iteration process, we can help you create a website that
                  captivates your audience and drives business success. Contact
                  us today to discuss your UI/UX development needs and elevate
                  your website's user experience to the next level.
                </p>
                <div className="row justify-content-center">
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={Photoshop}
                      className="img-hover"
                    />
                  </div>
                  <div className="col-2">
                    <img
                      alt="service-img"
                      src={Figma}
                      className="img-hover"
                    />
                  </div>
                  <div className="col-2">

                    <img alt="service-img"
                      src={AI} className="img-hover" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 order-md-1" data-aos="fade-right"  >
                <img
                  src={Uiux}
                  className="img mx-auto d-block"
                  alt="service-img"                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Service;
