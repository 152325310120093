import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./componet/Home";
import Apply from "./componet/Apply";
import Footer from "./componet/footer";
import GoToTop from "./componet/GoToTop";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/style/style.css";
import Navbar from "./componet/Navbar";
import { Container } from "@mui/material";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />{" "}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
