import React, { createContext, useState } from "react";

export const BasicContext = createContext(null);

const Basicprovider = ({ children }) => {
  const [editingRow, setEditingRow] = useState(null);
  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false) ;

  const handleShow = () => setShow(true) ;

  return (
    <BasicContext.Provider value={{ editingRow, setEditingRow, handleShow, show, handleCloseModal }}>
      {children}
    </BasicContext.Provider>
  );
};

export default Basicprovider;
