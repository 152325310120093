import "../style/Career.css";
import celebrations from "../images/celebrations.gif";
import games from "../images/games.gif";
import positive from "../images/positive.gif";
import learning from "../images/learning.gif";
import growth from "../images/growth.gif";
import paid_leaves from "../images/paid_leaves.gif";
import emp_first from "../images/employee-first.gif";
import training from "../images/flexible-working-hours.gif";
import regular_outing from "../images/regular_outing.gif";
import Mission from "../images/mission.gif";
import Header_left from "../images/Header-left.png";
import under from "../images/Underline.png"
import main_image from "../images/main circle.png"
function Career() {
  return (
    <>
      <div className="career-circle" id="career">
        <div className="animation-img-career rounded mx-auto d-block">
          <img src={Header_left} alt="Header_left" />
        </div>
        <div
          className="text-center text-capitalize"
          style={{ color: "var(--color-black)" }}
        >
          <div className="career-heading">
            <span className="career-span">Why Choose Us ?</span>
          </div>
          <img class="rounded mx-auto d-block pb-5" alt="under" src={under} style={{ width: "58%", marginTop: "-0.9%", marginBottom: "2%" }} />


          <div className="container carrer-hide-img" style={{width:"900px"}}>
            <img></img>
            <div className="square" style={{ left: "144.27px", top: "250.12px" }}>
              <div className="circle" />
              <div className="blueSquare" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={regular_outing}
                alt="Over Time 1"
              />
            </div>
            <div className="square" style={{ left: "503.87px", top: "502.42px" }}>
              <div className="circle" />
              <div className="blueSquare" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={paid_leaves}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "237.07px", top: "502.42px" }}>
              <div className="circle" />
              <div className="blueSquare" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={emp_first}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "592.32px", top: "250.12px" }}>
              <div className="circle" />
              <div className="blueSquare" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={celebrations}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "214.60px", top: "126.87px" }}>
              <div className="circle" />
              <div className="blueSquare2" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={training}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "144.27px", top: "390.04px" }}>
              <div className="circle" />
              <div className="blueSquare2" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={positive}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "592.32px", top: "390.04px" }}>
              <div className="circle" />
              <div className="blueSquare2" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={learning}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "517.64px", top: "126.87px" }}>
              <div className="circle" />
              <div className="blueSquare2" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={growth}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "275px", top: "200px" }}>
             <img src={main_image}/>
            </div>

            <div className="title" style={{ left: "143px", top: "564px" }}>
              Performance <br />Rewards
            </div>
            <div className="title" style={{ left: "101px", top: "145px" }}>
              Flexible <br />working hours
            </div>
            <div className="title" style={{ left: "74.05px", top: "275.50px" }}>
              Team <br />Outing.
            </div>
            <div className="description" style={{ left: "0px", top: "315.37px" }}>
              Never, ever underestimate the <br />importance of having fun
            </div>
            <div className="title" style={{ left: "41px", top: "420px" }}>
              Work<br />Environment
            </div>
            <div className="description" style={{ left: "10px", top: "459.87px" }}>
              Never, ever underestimate the <br />importance of having fun
            </div>
            <div className="description" style={{ left: "4px", top: "606px" }}>
              “You do great work; you deserve a huge thank you from <br />the entire office!”
            </div>
            <div className="title" style={{ left: "607.54px", top: "590.87px" }}>
              Paid<br />Leaves
            </div>
            <div className="description" style={{ left: "607.54px", top: "630.74px" }}>
              “The key is in not spending time, but in <br />investing it.”
            </div>
            <div className="square" style={{ left: "368px", top: "73.95px" }}>
              <div className="circle" />
              <div className="blueSquare" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={games}
                alt="Over Time 1"
              />      </div>
            <div className="square" style={{ left: "368px", top: "539.39px" }}>
              <div className="circle" />
              <div className="blueSquare2" style={{ left: "10.35px", top: "10.35px" }} />
              <div className="whiteSquare" style={{ left: "25.53px", top: "25.53px" }} />
              <img
                className="image"
                style={{ left: "32.76px", top: "33.57px" }}
                src={Mission}
                alt="Over Time 1"
              />      </div>
            <div className="title" style={{ left: "391px", top: "656.84px" }}>
              Mission
            </div>
            <div className="title" style={{ left: "391px", top: "0px" }}>
              Fun<br />Activity
            </div>
            <div className="description" style={{ left: "356px", top: "39.87px" }}>
              Never, ever underestimate the <br />importance of having fun
            </div>
            <div className="description" style={{ left: "1px", top: "190px" }}>
            Choose a job you love, and you will never have <br />importance of having fun
            </div>
            <div className="description" style={{ left: "356px", top: "680.84px" }}>
              Never, ever underestimate the <br />importance of having fun
            </div>
            <div className="title" style={{ left: "635.82px", top: "116.72px" }}>
              Career<br />Growth
            </div>
            <div className="description" style={{ left: "635.82px", top: "156.60px" }}>
              Choose a job you love, and you will never have <br />to work a day in your life
            </div>
            <div className="title" style={{ left: "707.59px", top: "275.50px" }}>
              Festival <br />Celebrations
            </div>
            <div className="description" style={{ left: "707.59px", top: "315.37px" }}>
              Festivals teach us to share joy <br />with each other.
            </div>
            <div className="title" style={{ left: "705px", top: "423px" }}>
              Empower<br />Learning
            </div>
            <div className="description" style={{ left: "705px", top: "462.87px" }}>
              “The more that you read, the more things <br />you will know, the more that you learn.”
            </div>
            
          </div>

        </div>
      
        <div className="row row-cols-1 row-cols-md-3 g-4 ms-2 me-2 d-flex justify-content-center mt-1 pb-5 main-circle">
          <div className="circle-container" style={{ marginBottom: "12%" }}>
            <div className="outer-circle">
              <div className="inner-circle-1"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={games} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Fun
              <br />
              Activity
            </p>
          </div>

          <div className="circle-container">
            <div className="outer-circle">
              <div className="inner-circle-4 "></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={training} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Flexible
              <br />
              working
            </p>
          </div>

          <div className="circle-container" style={{ marginBottom: "12%" }}>
            <div className="outer-circle">
              <div className="inner-circle-4"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img
                className="profile-image"
                src={regular_outing}
                alt="Profile"
              />
            </div>
            <p style={{ marginTop: "130%" }}>
              Team
              <br />
              Outing
            </p>
          </div>

          <div className="circle-container">
            <div className="outer-circle">
              <div className="inner-circle-1"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={positive} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Work
              <br />
              Environment
            </p>
          </div>

          <div className="circle-container " style={{ marginBottom: "12%" }}>
            <div className="outer-circle">
              <div className="inner-circle-1"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={emp_first} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Performance
              <br />
              Rewards
            </p>
          </div>

          <div className="circle-container">
            <div className="outer-circle">
              <div className="inner-circle-4"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={Mission} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>Mission</p>
          </div>

          <div className="circle-container" style={{ marginBottom: "12%" }}>
            <div className="outer-circle">
              <div className="inner-circle-4"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img
                className="profile-image"
                src={paid_leaves}
                alt="Profile"
              />
            </div>
            <p style={{ marginTop: "130%" }}>
              Paid
              <br />
              Leaves
            </p>
          </div>

          <div className="circle-container">
            <div className="outer-circle">
              <div className="inner-circle-1"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={learning} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Empower
              <br />
              Learning
            </p>
          </div>
          <div className="circle-container" style={{ marginBottom: "12%" }}>
            <div className="outer-circle">
              <div className="inner-circle-1"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img
                className="profile-image"
                src={celebrations}
                alt="Profile"
              />
            </div>
            <p style={{ marginTop: "130%" }}>
              Festival
              <br />
              Celebrations
            </p>
          </div>

          <div className="circle-container">
            <div className="outer-circle">
              <div className="inner-circle-4"></div>
              <div className="inner-circle-2"></div>
              <div className="inner-circle-3"></div>
              <img className="profile-image" src={growth} alt="Profile" />
            </div>
            <p style={{ marginTop: "130%" }}>
              Career
              <br />
              Growth
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Career;