import React from "react";
import BallCanvas from './Ball';
import "../../src/App.css"
import { SectionWrapper } from "../componet/hoc/index";
import  technologies  from "./constants/index";
import { motion } from "framer-motion";
import { textVariant } from "./motion";
import under from "../images/Underline.png"

const Tech = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
          <div className="expertise-heading">
             <span className="expertise-span">Our Expertise</span>
          </div>
          <img alt="technology-images" class="rounded mx-auto d-block pb-5" src={under} style={{width:"50%",marginTop:"-1%",marginBottom:"2%"}} />
        </motion.div>
    <div className="container" style={{display:"flex",justifyContent:"center",flexWrap:"wrap",flexDirection:"row",gap:"4rem",}}>
      {technologies.map((technology) => (
        <div style={{height:"7rem",width:"7rem"}} >
          <BallCanvas icon={technology.icon} title={technology.title}/>
        </div>
      ))}
    </div>
    </>
  );
};

export default SectionWrapper(Tech, "");
