import React from 'react';
import { Link as ScrollLink } from "react-scroll";
import { MDBContainer } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import { BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { AiOutlineMail} from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi"
import "../style/footer.css"
import img1 from "../images/i.png";

const Footer = ({ margin }) => {
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <footer className="footer-section" style={{ paddingLeft: "4%", marginTop: margin }}>
      <MDBContainer fluid> 
        <div className="footer-content pt-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4">
              <div className="footer-widget">
                <Link to='/' onClick={goToBtn}>
                  <img alt='' src={img1} className="logo w-50 h-50 ms-2" />
                </Link>


                <p class="footer-links">
                  <Link to='/' style={{ color: 'var(--color-white)' }} onClick={goToBtn}>Home</Link> |
                  <ScrollLink to='services' spy={true}
                    smooth={true}
                    offset={-380}
                    duration={500} style={{ color: 'var(--color-white)' }}>Services</ScrollLink> |
                  <ScrollLink to='career' spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500} style={{ color: 'var(--color-white)' }}>Career</ScrollLink>  |
                  <ScrollLink to='contact' spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500} style={{ color: 'var(--color-white)' }}>Contact</ScrollLink>
                </p>

                <p class="text-top copyright">Copyright © 2023 Ashirwad Infotech All rights reserved</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Office<div className="underline"><span></span></div> </h3>
                </div>
                <div className='footer-text mb-25'>
                  <p>418-419,Laxmi Enclave-2,</p>
                  <p> katargm,Surat</p>
                </div>
                <Link to='' className=" text-decoration-none" onClick={() => window.location = 'mailto:info@ashirwadinfotech.com'}>
                  <p className='email-id'>info@ashirwadinfotech.com</p>
                </Link>
                <Link to='' className=" text-decoration-none" onClick={() => window.location = 'https://wa.me/917600273027'}>  <h4 className='no'>(+91) 7600273027</h4></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 mb-0">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Newsletter <div className="underline"><span></span></div></h3>
                </div>
                <div className="footer-text mb-25">
                  <p>Our main goal is the satisfaction of clients and growing business relationships. we are working for turning your idea into reality</p>
                </div>
               
                <div className='social-icons'>
                  <Link to='' onClick={() => window.location = 'mailto:info@ashirwadinfotech.com'}>
                    <AiOutlineMail className='Mail' />
                  </Link>
                  <Link to='' onClick={() => window.location = 'https://www.linkedin.com/company/ashirwad-infotech/'}>
                    <BsLinkedin className='Linkdin' />
                  </Link>
                  <Link to='' onClick={() => window.location = 'https://wa.me/917600273027'}>
                    <BsWhatsapp className='Whatsapp' />
                  </Link>
                  <Link to='' onClick={() => window.location = 'https://g.page/Laxmi-Enclave-2?share'}>
                    <HiLocationMarker className='Marker' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MDBContainer>
      <hr />
      <p class="text-top hidecopyright">Copyright © 2023 Ashirwad Infotech All rights reserved</p>
    </footer>

  );
};

export default Footer;
