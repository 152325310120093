import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from 'styled-components';
import { AiOutlineArrowUp } from "react-icons/ai";

const theme = {
  colors: {
    btn: 'linear-gradient(131deg, #2081FE 0%, #0154CF 37.93%, #0154CF 100%)',
    shadow: 'gray',
  },
};

const GoToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
  let heightToHidden = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };                        
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {isVisible && (
          <div className="top-btn" onClick={goToBtn}>
            <AiOutlineArrowUp className="top-btn--icon"/>
          </div>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  .top-btn {
    font-size: 1.5rem;
    width: 2.5rem;
	height:2.5rem;
    color: #fff;
    background-image: ${({ theme }) => theme.colors.btn};
    box-shadow: ${({ theme }) => theme.colors.shadow};
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 0.8rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--icon {
      animation: gototop 1.2s linear infinite alternate-reverse;
    }
  }

  @keyframes gototop {
    0% {
      transform: translateY(-0.2rem);
    }
    100% {
      transform: translateY(0.2rem);
    }
  }
}`;

export default GoToTop;
