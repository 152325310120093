import React from 'react';
import "../style/Home_slider.css";
import slider from "../images/Header-slider.png"
import { Link } from 'react-scroll';
import Typewriter from "typewriter-effect";
import { ImHtmlFive2 } from 'react-icons/im';
import { FaJava, FaVuejs, FaFigma } from "react-icons/fa";
import { BiLogoReact, BiLogoFlutter } from 'react-icons/bi';
import { SiAngularjs } from 'react-icons/si';
import { TbBrandPython } from 'react-icons/tb';
import { GrGraphQl } from 'react-icons/gr'
import { DiPhotoshop } from 'react-icons/di'
import { GrSwift } from 'react-icons/gr';
import { AiOutlineAndroid } from 'react-icons/ai'
import { AiOutlineApple } from 'react-icons/ai'
import { styles } from "./style";
import { motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";

function Home_slider() {
  return (
    <>
      <div className='wrapper1' style={{ marginTop: "-15%" }} >
        <ul class="circles">
          <li><BiLogoReact className="icon" /></li>
          <li><FaJava className='icon' /></li>
          <li><ImHtmlFive2 className='icon' /></li>
          <li><SiAngularjs className='icon' /></li>
          <li><FaVuejs className='icon' /></li>
          <li><TbBrandPython className='icon' /></li>
          <li><GrGraphQl className='icon' /></li>
          <li><FaFigma style={{ color: "#61DBFB", height: "40px", width: "40px" }} className='icon' /> </li>
          <li><DiPhotoshop className='icon' /></li>
          <li><GrSwift className='icon' /></li>
          <li><AiOutlineAndroid className="icon" /></li>
          <li><AiOutlineApple className="icon" /></li>
          <li><BiLogoFlutter className='icon'></BiLogoFlutter></li>
        </ul>
        <div className='slider' id='Home'>
          <div className='slider_content'>
            <div style={{ display: "flex", }}>
              <div className='side-border' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                <div style={{ height: "1.5rem", width: "1.5rem", borderRadius: "50%", backgroundColor: "rgb(39, 140, 255)" }} />
                <div style={{ border: "1px solid rgb(39, 140, 255)", height: "15rem" }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: "2rem" }}>
                <h1 className={`${styles.heroHeadText} text-white `} >
                  Let's Build Online Success <br />Together With<span style={{ fontWeight: "600", color: "rgb(39, 140, 255)" }}> Standard & Influence</span>
                </h1>
                <div className='typewriter-content'>
                  <Typewriter
                    options={{
                      strings: ['We bring reality into your idea', 'We build invoative web and mobile app'],
                      autoStart: true,
                      loop: true,
                      delay: 100,
                      deleteSpeed: 20,
                      cursor: '|',
                      pauseFor: 1000,
                      wrapperClassName: 'typewriter-wrapper',
                      cursorClassName: 'typewriter-cursor',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <button className='button_slider'><Link to="contact" spy={true} smooth={true} offset={-150} duration={500} className='text-decoration-none'>Let's Get Connect</Link></button> */}
          </div>
          <div className='slider_image'>
            <img alt='' src={slider} />
          </div>
        </div>
        <div style={{ alignItems: "center", justifyContent: "center", display: 'flex' }}>
          <ScrollLink
            to="career"
            spy={true}
            smooth={true}
            offset={-2500}
            duration={500}
            style={{ cursor: "pointer", zIndex: "1" }}>
            <div style={{
              width: "35px",
              height: "64px",
              borderRadius: "3rem",
              border: "4px solid #fff",
              display: "flex",
              justifyContent: 'center',
              alignItems: "center",
              padding: "1rem",
              cursor: "pointer",
              zIndex: "1"
            }}>
              <motion.div
                style={{ border: "5px solid #fff", borderRadius: "2rem" }}
                animate={{
                  y: [0, 24, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "loop",

                }}
                className='w-3 h-3 rounded-full bg-[#fff] mb-1 '
              />
            </div>
          </ScrollLink>
        </div>
      </div>
    </>
  )
}

export default Home_slider