import React, { useEffect, useState, useContext } from "react";
import { BasicContext } from "../componet/context/Basicproviders";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { SectionWrapper } from "../componet/hoc";
import { Grid, Typography } from "@mui/material";
import { baseUrl } from "./api";
import { GrLocation } from "react-icons/gr";
import { RiUserSearchLine } from "react-icons/ri";
import { PiMedal } from "react-icons/pi";
import "../style/current_job.style.css";
import axios from "axios";
import under from "../images/Underline.png";
import Navbar from "../componet/Navbar"
import Apply from "./Apply";
import { useHistory } from "react-router-dom";

export const textVariant = (delay) => {
    return {
        hidden: {
            y: -50,
            opacity: 0,
        },
        show: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                duration: 1.25,
                delay: delay,
            },
        },
    };
};

export const fadeIn = (direction, type, delay, duration) => {
    return {
        hidden: {
            x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
            y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
            opacity: 0,
        },
        show: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                type: type,
                delay: delay,
                duration: duration,
                ease: "easeOut",
            },
        },
    };
};

const CurrentJob = () => {
    const { handleShow } = useContext(BasicContext)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [position, setPositions] = useState([])
    const [jobdata, setJobdata] = useState([]);
    const [modalJobId, setModalJobId] = useState(null);
    const history = useHistory();

    const handleButtonClick = (jobId) => {
        setModalJobId(jobId);
        handleShow();
    };

    useEffect(() => {
        try {
            axios.get(baseUrl + "/jobs/").then((res) => {
                setPositions(res.data);
            }).catch((error) => {
                console.log(error)
            });
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(baseUrl + "/jobs/");
                console.log("response", response.data);
                setJobdata(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("Error fetching data");
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div></div>;
    }
    return (
        <>
            <Navbar showApplyButton={jobdata.length > 0} />

            <div className="current-heading" id="CurrentJob">
                <span className="current-span">Current Job Opening</span>
            </div>
            <img
                className="rounded mx-auto d-block pb-5"
                src={under}
                style={{ width: "90%", marginTop: "-0.6%" }}
                alt=""
            />

            <div className="flex flex-wrap gap-2 m-auto justify-content-center d-flex container">
                {jobdata.map((value, index) => (
                    <div key={index}>
                        <div className=" flex flex-wrap justify-content-center">
                            <Tilt className="xs:w-[370px] w-full">
                                <div style={{ boxShadow: "1px red" }}>
                                    <motion.div
                                        variants={fadeIn("right", "spring", index * 0.9, 0.75)}
                                        className="w-full green-pink-gradient p-2 rounded-4 m-3 shadow-card justify-content-center"
                                        style={{
                                            width: "330px",
                                            background: "rgb(249 249 249)",
                                            border: "4px solid #02176C", borderRight: "none"
                                        }}>
                                        <div
                                            options={{
                                                max: 45,
                                                scale: 8,
                                                speed: 450,
                                            }}
                                            className="bg-tertiary rounded py-4 px-4 flex justify-evenly items-center flex-col"
                                        >
                                            <div >
                                                <Grid container spacing={2}>
                                                    <Grid>
                                                        <div
                                                            style={{ display: "flex", alignItems: "center", }}
                                                        >
                                                            {value.image ? <img
                                                                className="job-image"
                                                                src={value.image}></img> : <img
                                                                    className="job-image "
                                                                    src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.iconfinder.com%2Ficons%2F6856396%2Fcode_html_tag_web_website_icon&psig=AOvVaw1rU56QdhcYpyLykQ8kZCa-&ust=1712053746594000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCNji0LHnoIUDFQAAAAAdAAAAABAD"></img>}

                                                            <div>
                                                                <Typography
                                                                    variant="h6"
                                                                    style={{
                                                                        textTransform: "capitalize",
                                                                        fontWeight: "600",
                                                                        background:

                                                                            "-webkit-linear-gradient(131deg, #143058 0%, #1A6590 100%)",
                                                                        WebkitBackgroundClip: "text",
                                                                        WebkitTextFillColor: "transparent",
                                                                        marginLeft: "0.7rem",
                                                                    }}
                                                                >
                                                                    {value.position_name}
                                                                </Typography>
                                                                <div
                                                                    className="d-flex"
                                                                    style={{
                                                                        marginTop: "0.5rem",
                                                                        marginLeft: "0.1rem",
                                                                    }}
                                                                >
                                                                    <Typography variant="subtitle1 flex">
                                                                        <RiUserSearchLine
                                                                            style={{
                                                                                fontSize: "18px",
                                                                            }}
                                                                        />{" "}
                                                                        {value.n_position} Position
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{
                                                                            textTransform: "capitalize",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        <PiMedal
                                                                            style={{
                                                                                fontSize: "18px",
                                                                                marginLeft: "12px",
                                                                            }}
                                                                        />
                                                                    </Typography>{" "}
                                                                    {value.experience}{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                maxHeight: "100px",
                                                                height: "100px",
                                                            }}
                                                            className="job_description"
                                                        >
                                                            {value.description}
                                                        </Typography>
                                                        <div className="button-location  d-flex justify-content-between">
                                                            {/* <Link to={`/apply/${value.id}`}> */}
                                                            <button
                                                                className="job-button"
                                                                onClick={() => handleButtonClick(value.id)}
                                                                style={{
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: "600",
                                                                    background: '-webkit-linear-gradient(102deg, #02176C 9.82%, #0076B6 93.22%)',
                                                                    WebkitBackgroundClip: 'text',
                                                                    WebkitTextFillColor: 'transparent'
                                                                }}
                                                            >
                                                                Apply For Job
                                                            </button>
                                                            {/* </Link> */}
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                    display: "flex",
                                                                }}
                                                            >
                                                                <GrLocation style={{ fontSize: "20px" }} />{" "}
                                                                {value.location}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </motion.div>
                                </div>
                            </Tilt>
                        </div>
                    </div>
                ))}
            </div>
            <Apply jobId={modalJobId} handleCloseModal={() => setModalJobId(null)} setModalJobId={setModalJobId} />
        </>
    );
};
export default SectionWrapper(CurrentJob);