import Home_slider from "./Home_slider";
import Career from "./career";
import Service_new from "./Services";
import Technology from "./technology";
import Cureent_job from "./Current_job";
import Contact from "./contact";
import Navbar from "./Navbar";
import background from "../images/background.png";
import Header_design from "./Header_design";
import Footer from "./footer";
import { Container } from "@mui/material";
import GoToTop from "./GoToTop";

function Home() {
  return (
    <>
      <Container
        disableGutters
        maxWidth="xxl"
        sx={{
          backgroundImage: `url(${background})`,
        }}
      >
        <Navbar />
        <Home_slider />
        <Header_design />
        <Service_new />
        <Technology />
        <Career/>
        <Cureent_job />
        <Contact />
        <Footer margin="48rem"/> 
        <GoToTop/>
      </Container>
    </>
  );
}
export default Home;
