import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logo from "../images/i.png";
import "../style/Navbar.style.css";
import Sidebar from "./sidebar/Sidebar";
import "../style/Navbar.style.css";

const Navbar = ({ showApplyButton }) => {
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 170) {
        setNavbarBackground(
          "linear-gradient(131deg, #02176C 0%, #0076B6 100%)"
        );
      } else {
        setNavbarBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
  
      <Box>
        <AppBar
          component="nav"
          sx={{
            background: navbarBackground,
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              color="goldenrod"
              variant="h6"
              sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
            >
              <NavLink activeClassName="navlink" to={"/"} onClick={goToBtn}>
                <img src={logo} alt="logo" height={60} width="200" />
                <div className="liner"></div>
              </NavLink>
              <ul className="navigation-menu active">
                <li>
                  <ScrollLink
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-380}
                    duration={500}
                    className="text-decoration-none align-items-start link text-white"
                  >
                    SERVICES
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="career"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    className="text-decoration-none link text-start text-white"
                  >
                    CAREER
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    className="text-decoration-none link text-start text-white"
                  >
                    CONTACT US
                  </ScrollLink>
                </li>

              </ul>
            <Sidebar />

            </Typography>

            {showApplyButton && (
              <ScrollLink
                to="CurrentJob"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
                className="text-decoration-none nav-button"
              >
                Apply For Job
              </ScrollLink>
            )}
          </Toolbar>

        </AppBar>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
