import React from "react";
import { GoDotFill } from "react-icons/go";
import img1 from "../images/Apply.png";
import Modal from "@mui/material/Modal";
import background from "../images/background.png";
import { baseUrl } from "./api";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { BasicContext } from "../componet/context/Basicproviders";
import { Box } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import "../../src/style/current_job.style.css"

const Apply = ({ jobId, handleCloseModal, setModalJobId }) => {
    const [reqired, setrequired] = useState("");
    const [positionName, setpositionName] = useState("");
    const history = useHistory();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        backgroundColor: "#fff",
        boxShadow: 24,
        overflowY: "auto",
        borderRadius: "0.5rem",
        display: "flex",
        '@media (max-width: 600px)': {
            width: '90%',
            height: '90%',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    };

    useEffect(() => {
        if (jobId) {
            try {
                axios.get(baseUrl + "/jobs/" + jobId + "/").then((res) => {
                    setrequired(res.data.company_requirement);
                    setpositionName(res.data.position_name);
                }).catch((error) => {
                    console.log(error);
                })
            } catch (error) {
                throw error
            }
        }
    }, [jobId]);
    const splitsentences = reqired.split(".").filter((part) => part.trim() !== '');

    const submitform = (values, { resetForm }) => {
        const _formdata = new FormData();
        _formdata.append("your_name", values.your_name);
        _formdata.append("email", values.email);
        _formdata.append("phone", values.phone);
        _formdata.append("notice_period", values.notice_period);
        _formdata.append("experience", values.experience);
        _formdata.append("expected_salary", values.expected_salary);
        _formdata.append("current_salary", values.current_salary);
        _formdata.append("resume", values.resume);
        _formdata.append("meet_time", values.meet_time);
        _formdata.append("location", values.location);
        _formdata.append("position_name", positionName)
        try {
            axios
                .post(baseUrl + "/applyjob/", _formdata, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    
                        Swal.fire({
                            title: "Data has been submited",
                            icon: "success",
                            toast: true,
                            timer: 3000,
                            position: "top-right",
                            timerProgressBar: true,
                            showConfirmButton: false,
                        });
                    

                        resetForm();
                        setModalJobId(null)
                    handleCloseModal();

                }).catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            throw error
        }
    };

    return (
        <>
            <Modal
                open={!!jobId}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box className="apply-modal">
                    <div className="requirements" style={{ background: "#012d78" }}>
                        <IoMdClose size={"25px"} onClick={handleCloseModal} className="close-icon" />

                        <h3 className="fw-bold" style={{ paddingTop: "2rem" }}>
                            REQUIRED SKILLS & QUALIFICATION
                        </h3>
                        <div style={{paddingTop:"2.5rem"}}>
                            {splitsentences.length > 0 ? (
                                splitsentences.map((value) => (
                                    <div className="ms-3 pt-2" style={{ color: "#fff" }}>
                                        <GoDotFill
                                            className="fs-5"
                                        />
                                        <span>{value.trim()}.</span>
                                    </div>
                                ))
                            ) : (
                                <div className="ms-3 pt-3" style={{ color: "#02176C" }}>
                                    <GoDotFill
                                        className="fs-5 me-2"
                                    />
                                    {reqired}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="apply-form" style={{marginTop:"2rem" }}>

                        <h3 className="fw-bold text-capitalize" >
                             Apply For {positionName} Job
                        </h3>

                        <Formik
                            onSubmit={submitform}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleSubmit,
                                handleBlur,
                                handleChange,
                            }) => (
                                <form className="row g-2 pt-5" style={{ border: "none" }}>
                                    <div className="col-md-4 p-1" >
                                        <TextField
                                            border="2px solid #02176ca2"
                                            id="outlined-basic"
                                            label="Enter name*"
                                            variant="outlined"
                                            className="w-100"
                                            name="your_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.your_name}
                                            error={!!touched.your_name && !!errors.your_name}
                                            helperText={touched.your_name && errors.your_name}
                                        />
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Email*"
                                            variant="outlined"
                                            className="w-100"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                        />
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Mobile number*"
                                            variant="outlined"
                                            className="w-100"
                                            name="phone"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone}
                                            error={!!touched.phone && !!errors.phone}
                                            helperText={touched.phone && errors.phone}
                                        />
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Current Location*"
                                            variant="outlined"
                                            className="w-100"
                                            name="location"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.location}
                                            error={!!touched.location && !!errors.location}
                                            helperText={touched.location && errors.location}
                                        />
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Experience*"
                                            variant="outlined"
                                            className="w-100"
                                            name="experience"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.experience}
                                            error={!!touched.experience && !!errors.experience}
                                            helperText={touched.experience && errors.experience}
                                        ></TextField>
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Current Salary*"
                                            variant="outlined"
                                            className="w-100"
                                            name="current_salary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.current_salary}
                                            error={!!touched.current_salary && !!errors.current_salary}
                                            helperText={touched.current_salary && errors.current_salary}
                                        />
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Expected Salary*"
                                            variant="outlined"
                                            className="w-100"
                                            name="expected_salary"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expected_salary}
                                            error={!!touched.expected_salary && !!errors.expected_salary}
                                            helperText={touched.expected_salary && errors.expected_salary}
                                        />
                                    </div>

                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="If selected, when we meet?*"
                                            className="w-100"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="meet_time"
                                            value={values.meet_time}
                                            error={!!touched.meet_time && !!errors.meet_time}
                                            helperText={touched.meet_time && errors.meet_time}
                                        >
                                            <MenuItem value="immedidate">Immediate</MenuItem>
                                            <MenuItem value="day">day</MenuItem>
                                            <MenuItem value="week">Week</MenuItem>
                                            <MenuItem value="months">Month</MenuItem>
                                        </TextField>
                                    </div>
                                    <div className="col-md-4 p-1">
                                        <TextField
                                            id="outlined-basic"
                                            label="Notice Period*"
                                            variant="outlined"
                                            className="w-100"
                                            name="notice_period"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.notice_period}
                                            error={!!touched.notice_period && !!errors.notice_period}
                                            helperText={touched.notice_period && errors.notice_period}
                                        />
                                    </div>

                                    <div className="col-12 mb-4">

                                        <div style={{ marginBottom: '1rem', }}>
                                            <ButtonGroup
                                                disableElevation
                                                variant="contained"
                                                aria-label="Disabled elevation buttons"
                                            >
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                <Button
                                                    className="text-capitalize btn-lg"
                                                    style={{
                                                        margin: "5px",
                                                        borderRadius: "5px",
                                                        background: `var(--bgcolor-main)`,
                                                        color: "white",
                                                        fontSize: "1rem"
                                                    }}
                                                    onClick={() => {
                                                        document.querySelector('input[type="file"]').click();
                                                    }}
                                                >
                                                    Upload CV/Resume
                                                </Button>
                                                <input
                                                    type="file"
                                                    accept=".pdf,.doc,.docx"
                                                    name="resume"
                                                    style={{ display: "none" }}
                                                    onChange={(event) => {
                                                        setFieldValue("resume", event.currentTarget.files[0]);
                                                    }}
                                                    error={!!touched.resume && !!errors.resume}
                                                    helperText={touched.resume && errors.resume}
                                                />
                                            
                                                    {values.resume && (
                                                        <div style={{ fontSize: '1rem' }}>
                                                            <p> {values.resume.name}</p>
                                                        </div>
                                                    )}
                                                    {touched.resume && errors.resume && (
                                                        <p style={{ color: 'red', fontSize: '1rem' }}> * {errors.resume}</p>
                                                    )}
                                                </div>
                                                <Button
                                                    className="text-capitalize btn-lg apply-btn"
                                                    style={{
                                                        borderRadius: "5px",
                                                        border: "2px solid #02176ca2",
                                                        background: "white",
                                                        color: "black", 
                                                         height:"2.6rem",
                                                         margin:"0.3rem 2rem",
                                                         width:"6rem"
                                                    }}
                                                    onClick={handleSubmit}
                                                >
                                                    Apply
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>

                                </form>
                            )}
                        </Formik>

                    </div >
                </Box >
            </Modal >
        </>
    );
};
const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const phoneNumeric = /^\d{10}$/;
const checkoutSchema = yup.object().shape({
    your_name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    location: yup.string().required("Required"),
    current_salary: yup.string().required("Required"),
    expected_salary: yup.string().required("Required"),
    experience: yup.string().required("Required"),
    meet_time: yup.string().required("Required"),
    notice_period: yup.string().required("Required"),
    resume: yup
        .mixed()
        .test("fileRequired", "Please upload a file", (value) => {
            return value && value !== null;
        }),
    phone: yup
        .string()
        .matches(phoneRegExp, "Enter only numeric")
        .matches(phoneNumeric, "Phone number is not valid")
        .required("Required"),
});


const initialValues = {
    your_name: "",
    email: "",
    phone: "",
    experience: "",
    current_salary: "",
    location: "",
    expected_salary: "",
    meet_time: "",
    notice_period: "",
    resume: null,
};

export default Apply;





