import React from 'react'
import '../style/Header_design.css'
import Header_right from "../images/Header_rigth.png"
import Header_left from "../images/Header-left.png"
import under from "../images/Underline.png"
import background from "../images/background.png"



function Header_design() {
    return (
        <div id="services">
            <div className="relative"  style={{ zIndex: "0" }} ></div>
            <div className="absolute" style={{ backgroundImage: `url(${background})` }} >
                <div style={{ paddingTop: "-1%" }}>
                    <div className="service-heading">
                        <span className="service-span"> Service We Provide</span>
                    </div>
                    <img class="rounded mx-auto d-block pb-5" src={under} style={{ width: "65%", marginTop: "-5.3%" }} />
                    <div className="footer-widget-heading-text text-center ">The Practice Of Providing Existing And Potential Customers With Support To </div>
                    <div className="footer-widget-heading-text text-center ">Enhance Their Satisfaction With The Company And Its Products Or Services. </div>
                    <div className="image-Header_left rounded float-left">
                        <img className="Header_img" src={Header_right} alt="Image" />
                    </div>
                    <div className="image-Header_right rounded float-right">
                        <img className="Header_img" src={Header_left} alt="Image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header_design;